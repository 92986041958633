import { useState, useEffect } from "react";
import LogoDiadWhite from "../../assets/logodiadunjbgwhite.png";
import { Dropdown, Menu, message, Select } from "antd";
import { MenuOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { logout } from "../../api/auth";
import { getPeriodo, setPeriodo, setProcesoStorage } from "../../utils/functions";
import * as SHARED from "../../utils/shared";
import * as AppApi from "../../api/sistema";
import { useAuth } from "../../utils/useAuth";
import { useHistory } from "react-router-dom";

const handleLogout = () => {
  message.info("¡Hasta luego!");
  logout();
};

const { Option } = Select;
const idPeriodo = getPeriodo()?.idperiodo || 0;

function HeaderAdmin(props) {
  const { periodos } = useAuth();
  const history = useHistory();
  const { usuario, showDrawer } = props;

  const [dataPeriodo, setDataPeriodo] = useState([
    {
      idperiodo: null,
      idinstitucion: null,
      denominacion: "SIN PERIODO"
    }
  ]);
  const [isLoadingPeriodo, setIsLoadingPeriodo] = useState(false);

  useEffect(() => {
    setIsLoadingPeriodo(true);
    AppApi.executeQuery({ query: "get_periodos_sistema" })
      .then((data) => {
        if (data.payload.length) {
          if (!!periodos) {
            const arrayPeriodos = periodos.split(",");
            const tmp = data.payload.filter((x) => arrayPeriodos.includes(x.idperiodo.toString()));
            setDataPeriodo(tmp);
          } else {
            setDataPeriodo(data.payload);
          }
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingPeriodo(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (select) => {
    const periodoSelected = dataPeriodo.find((i) => i.idperiodo === select);
    setPeriodo(periodoSelected);
    setProcesoStorage(periodoSelected?.idproceso);
    window.location.reload();
  };

  return (
    <header
      className="flex justify-between items-center w-full h-16 p-4"
      style={{ background: "#D41D3D" }}
    >
      <span className="flex items-center">
        <span className="mr-4 md:hidden">
          <button onClick={showDrawer}>
            <MenuOutlined className="text-white" />
          </button>
        </span>
        <img src={LogoDiadWhite} width={150} alt="Logo DIAD-UNJBG" className="hidden md:inline" />
      </span>

      <h1 className="hidden md:block text-white font-semibold text-base">
        {SHARED.PROCESO + " - " + SHARED.NOMBRE_SISTEMA}
      </h1>

      <div className="flex justify-center">
        <Select
          loading={isLoadingPeriodo}
          defaultValue={idPeriodo}
          onSelect={handleSelect}
          className="min-w-xxs md:min-w-xs"
        >
          {dataPeriodo.map((item, index) => (
            <Option key={index} value={item.idperiodo}>
              {item.denominacion}
            </Option>
          ))}
        </Select>
        <Dropdown.Button
          overlay={
            <Menu>
              <Menu.Item
                key="2"
                icon={<UserOutlined />}
                onClick={() => history.replace("/app/micuenta")}
              >
                Mi cuenta
              </Menu.Item>
              <hr />
              <Menu.Item key="1" icon={<LogoutOutlined />} onClick={handleLogout}>
                Cerrar sesión
              </Menu.Item>
            </Menu>
          }
          placement="bottomLeft"
          icon={<UserOutlined />}
        >
          <div className="lg:hidden">{usuario?.usuario.charAt(0).toUpperCase() || "U"}</div>
          <div className="hidden lg:block">{usuario.usuario || "Usuario"}</div>
        </Dropdown.Button>
      </div>
    </header>
  );
}

export default HeaderAdmin;
