/* eslint-disable */
/**
 * Functions
 * ==========
 * - Conjunto de funciones que ayudan en características específicas
 *   como transformar un texto en formato url.
 *
 * - Creado por: Dalthon - Abril 01, 2021 08:04 PM (GMT-5)
 */

/**
 * handleResponse
 * Muestra error según como se haga la petición
 * @param {Object} response
 */

import { ACCESS_TOKEN } from "../api/auth";
import { BASE_PATH } from "../api/api";
import { message, notification } from "antd";
import * as AppApi from "../api/sistema";
// Configura como idioma español al moment
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
moment.locale("es");
const PERIODO = "periodo";
const PROCESO = "proceso";

export const viewFileByUrl = (rutaArchivo) => {
  try {
    return `${BASE_PATH}/api/core/panel/view${rutaArchivo}?token=${ACCESS_TOKEN}`;
  } catch (err) {
    console.log(err);
    return null;
  }
};

// moment configuration
moment.updateLocale("es", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "now",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1mth",
    MM: "%dmth",
    y: "1y",
    yy: "%dy"
  }
});

export const handleResponse = (response) => {
  if (response.errors && response?.errors.length == 1) {
    throw new Error(response.errors[0].message);
  }

  if (!response?.success) {
    throw new Error(response?.message);
  }

  return response;
};

/**
 * Abre seguramente otra pestaña
 * @param {String} url
 * Maintaining href “open in new tab” with an onClick handler in React
 * https://stackoverflow.com/a/63627688/9868383
 */
export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const obtieneSaludoSegunHora = () => {
  let saludo = "";
  let date = new Date();
  let hora = date.getHours();

  if (hora < 12) {
    saludo = "Buenos días";
  } else {
    if (hora < 19) {
      saludo = "Buenas tardes";
    } else {
      saludo = "Buenas noches";
    }
  }

  return saludo;
};

/**
 * generaMensajeWhatsapp
 */
export const generaMensajeObservacionWhatsapp = ({
  celular,
  nombre,
  apellidopaterno,
  apellidomaterno,
  documentoregistro,
  observacionexterno
}) => {
  return (
    "https://api.whatsapp.com/send/?phone=51" +
    celular +
    "&text=" +
    obtieneSaludoSegunHora() +
    ".%0A*" +
    apellidopaterno +
    " " +
    apellidomaterno +
    ", " +
    nombre +
    " " +
    "(" +
    documentoregistro +
    ")" +
    "*%0ALe escribimos de la Dirección de Admisión, Universidad Nacional Jorge Basadre Grohmann (Tacna)" +
    "%0A%0ATiene la siguiente observación:%0A*" +
    observacionexterno +
    "*%0ADebe subsanar su observación *a la brevedad posible*"
  );
};

/**
 * dateLikeName
 * ------------
 * usado para generar nombre de archivo
 * ejemplo: 20210414_1250.xlsx
 * @returns String
 * How do I get the current date in JavaScript?
 * https://stackoverflow.com/a/4929629/9868383
 */
const dateLikeName = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var hour = String(today.getHours()).padStart(2, "0");
  var min = String(today.getMinutes()).padStart(2, "0");

  return yyyy + "-" + mm + "-" + dd + "_" + hour + "." + min;
};

export const DATE_LIKE_NAME = dateLikeName();

/**
 * shortName
 * ---------
 * Saca la primera palabra del nombre y apellido
 */

export const shortName = (nombres = "", apellidos = "") => {
  if (!nombres || !apellidos) return "";

  return nombres.split(" ")[0] + " " + apellidos.split(" ")[0];
};

export const uploadProps = (body) => {
  const {
    name,
    action,
    data,
    maxSize = 10,
    maxSizeImage = 2,
    makeReload = null,
    afterOnChange = null,
    reloadPage = null
  } = body;

  return {
    name,
    action: `${BASE_PATH}${action}`,
    headers: { authorization: ACCESS_TOKEN || "" },
    data,
    beforeUpload(file) {
      if (file.type === "image/jpeg") {
        const isLt1M = file.size / 1024 / 1024 <= maxSizeImage;
        if (!isLt1M) {
          message.error(`El archivo debe pesar máximo ${maxSizeImage}Mb`);
          return isLt1M;
        }
      }

      const isLt2M = file.size / 1024 / 1024 <= maxSize;
      if (!isLt2M) {
        message.error("El archivo debe pesar máximo 10Mb");
      }
      return isLt2M;
    },
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} subido correctamente`);
        if (makeReload) makeReload();
        if (afterOnChange) afterOnChange(info);
        if (reloadPage) location.reload();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} falló en subir.`);
      }
    }
  };
};

/**
 * uploadDocument
 * --------------
 * Contiene la estructura para enviar los archivos
 * al servidor.
 * type:
 * documento-identidad | partida-nacimiento
 * certificado-estudios | voucher-constancia |
 * renuncia-carrera
 *
 * @param {String} type
 * @param {String} documentoregistro
 * @returns
 */
// export const uploadDocument = ({type = "", documentoregistro = null, maxSize = 10}) => {
//   return uploadProps({
//     name: "attached",
//     action: "/sistema/upload-files",
//     data: { type, documentoregistro },
//     maxSize
//   });
// };

/**
 * openNotificationWithIcon
 * ------------------------
 * @param {String} type success, info, error, ...
 * @param {String} message notificacion message
 * @returns
 */
export const openNotificationWithIcon = (type = "", message = "") => {
  if (!type || !message) return window.alert("No hay información para mostrar en notificación");

  notification[type]({
    message: message
  });
};

/**
 * filterRepeatedAndReturnUniqueValues
 * -----------------------------------
 * Filtra array de objetos repetidos y los
 * devuelve en un array, ejemplo:
 *
 * input: [{id: 1, name: "uno", id: 2, name: "dos", id:· name: "dos"}]
 * output: ['uno', 'dos']
 *
 * Referencias:
 * ------------
 * How to remove all duplicates from an array of objects?
 * https://stackoverflow.com/a/56757215/9868383
 *
 * @param {ArrayOfObjects} arrayObjects
 * @param {String} uniqueKey
 * @returns {Array}
 */
export const filterRepeatedAndReturnUniqueValues = (arrayObjects = [], uniqueKey = "") => {
  if (uniqueKey == null || !uniqueKey) return [];

  // array de objetos con valores unicos
  const tempUniqueObjects = arrayObjects.filter(
    (v, i, a) => a.findIndex((t) => t[uniqueKey] === v[uniqueKey]) === i
  );

  // obtener en un solo array los valores no repetidos
  const uniqueValues = tempUniqueObjects.map((data) => data[uniqueKey]);

  // ordenar salida alfabeticamente
  return uniqueValues.sort();
};

/**
 * calculaPorcentaje
 * ================
 * @param {Number} paramNumerador
 * @param {Number} paramDenominador
 * @returns {Number} porcentaje calculado
 */
export const calculaPorcentaje = (paramNumerador, paramDenominador = 1) => {
  if (paramDenominador == 0 || paramDenominador == null) return 0;
  const porcentaje = (paramNumerador / paramDenominador) * 100;
  return porcentaje.toFixed(2);
};

export const calculaColor = (color = "") => {
  const colorsAvailable = {
    ROJO: "red",
    AMARILLO: "yellow",
    VERDE: "green",
    ROSADO: "deeppink"
  };

  return colorsAvailable[color] || "white";
};

export const setPeriodo = (periodo) => {
  return localStorage.setItem(PERIODO, JSON.stringify(periodo));
};

export const setProcesoStorage = (proceso) => {
  return localStorage.setItem(PROCESO, JSON.stringify(proceso));
};

export const getProcesoStorage = () => {
  return JSON.parse(localStorage.getItem(PROCESO));
};

export const getPeriodo = () => {
  if (localStorage.getItem(PERIODO)) {
    const periodo = JSON.parse(localStorage.getItem(PERIODO));
    return {
      idperiodo: periodo.idperiodo || null,
      idinstitucion: periodo.idinstitucion || null,
      denominacion: periodo.denominacion || null,
      idmodalidadingreso: periodo.idmodalidadingreso || null,
      idfacultad: periodo.idfacultad || null
    };
  }

  return {
    idperiodo: null,
    idinstitucion: null,
    denominacion: "SIN PERIODO",
    idmodalidadingreso: null,
    idfacultad: null
  };
};

/**
 * Para llenar los combo box
 * @param {Number} idtipocatalogo
 */
export const getDataCatalogo = async (idtipocatalogo) => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_catalogo_by_tipo",
      variables: [{ name: "TIPO", value: idtipocatalogo }]
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 * @param {Number} idtipocatalogo
 */
export const getDataCatalogoLike = async (idtipocatalogo) => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_catalogo_by_tipo_like",
      variables: [{ name: "TIPO", value: idtipocatalogo, type: "string" }]
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 */
export const getListaInstituciones = async () => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_lista_instituciones"
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 */
export const getListaFacultades = async () => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_lista_facultades"
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 */
export const getListaProcesos = async () => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_lista_procesos"
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 */
export const getListaPeriodos = async () => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_all_periodos_select"
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 */
export const getListaRequisito = async () => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_requisitos_select"
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 */
export const getListaConceptos = async () => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_concepto_select"
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 */
export const getListaCondicionPago = async () => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_condicion_pago_select"
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 */
export const getListaCanales = async () => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_canales_select"
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 */
export const getListaDireccionUgel = async () => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_direccion_ugel_select"
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Para llenar los combo box
 */
export const getListaPeriodosInscripcionActiva = async () => {
  try {
    const data = await AppApi.executeQuery({
      query: "get_all_periodos_inscripcion_activa_select"
    });
    return data.payload;
  } catch (error) {
    return [];
  }
};

/**
 * Left join javascript
 * @param {Array} objArr1
 * @param {Array} objArr2
 * @param {String} key1
 * @param {String} key2
 */
export const leftJoin = (objArr1, objArr2, key1, key2) => {
  return objArr1.map((anObj1) => ({
    ...objArr2.find((anObj2) => anObj1[key1] === anObj2[key2]),
    ...anObj1
  }));
};

export const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
