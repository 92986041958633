import Lista from "./Lista";
import { Tabs } from "antd";
import CIngresante from "./CIngresante";

const Constancia = () => {
  return (
    <div className="p-4 mx-auto text-center w-full">
      <div className="">
        <p className="text-base font-bold">ENTREGA DE CONSTANCIAS</p>
      </div>

      <Tabs defaultActiveKey="1" centered>
        <Tabs.TabPane tab="LISTA" key="1">
          <Lista />
        </Tabs.TabPane>
        <Tabs.TabPane tab="REGISTRO" key="2">
          <CIngresante />
        </Tabs.TabPane>
      </Tabs>

      {/* <div className="w-full">
        <Lista />
        <CIngresante />
      </div> */}
    </div>
  );
};

export default Constancia;
