const PROCESO = "PANEL";
const NOMBRE_SISTEMA = "PORTAL DE ADMISIÓN";
const TITLE_SISTEMA_SHORT = "Portal de Admisión";
const TITLE_SISTEMA_ABREV = "PORTAL-ADMISION";

const LABEL_ESTADO = {
  1: "Incompleto", // cerrado
  2: "Pendiente", // cerrado
  3: "Observado", // cerrado
  4: "Aceptado para VPI", // abierto si vpi esta abierto
  5: "VPI finalizado", // abierto
  6: "Inscrito", // abierto
  7: "Aceptado para Biométrico"
};

const LABEL_ESTADO_INGRESANTE = {
  0: "Anulado", // cerrado
  1: "Incompleto", // cerrado
  2: "Pendiente", // cerrado
  3: "Observado", // cerrado
  4: "Firmado" //
};

const COLOR_ESTADO = {
  1: "default",
  2: "blue",
  3: "orange",
  4: "teal",
  5: "purple",
  6: "green",
  7: "cyan"
};

const COLOR_ESTADO_INGRESANTE = {
  0: "pink",
  1: "default",
  2: "blue",
  3: "orange",
  4: "green"
};

const COLOR_ESTADO_TAILWIND = {
  1: "bg-gray-200",
  2: "bg-blue-300",
  3: "bg-red-300",
  4: "bg-indigo-200",
  5: "bg-purple-400",
  6: "bg-green-300",
  7: "bg-cyan-200"
};

const ESTADO = {
  incompleto: 1,
  pendiente: 2,
  observado: 3,
  aceptado: 4,
  llenadovpi: 5,
  inscrito: 6,
  aceptadobiometrico: 7
};

export const TIPO_REQUISITO = {
  persona: 3116,
  postulante: 3117
};

export const TIPO_CATALOGO = {
  sexo: "0020",
  tipo_documento: "0030",
  estado_civil: "0010",
  pais: "0040",
  zona: "0150",
  via: "0160",
  preparacionuniversitaria: "0190",
  modalidadingreso: "02402",
  deporte: "1151",
  modalidadextraordinario: "686",
  extraordinario_primeros_puestos: "688",
  extraordinario_graduados_universiad: "3130",
  extraordinario_titulado_instituto_superior: "3130",
  extraordinario_graduado_policia_oficiales: "691",
  extraordinario_deportista: "695",
  extraordinario_personas_discapacidad: "696",
  extraordinario_traslado_interno: "698",
  extraordinario_traslado_externo: "699",
  extraordinario_plan_integral_reparaciones: "1659",
  tipotrabajo: "1152",
  sectortrabajo: "1153",
  relacionparentesco: "0100",
  medioinformacion: "1100",
  nivelgrado: "1154",
  tiporequisito: "1150",
  tipoprograma: "0390",
  nivelmodalidad: "0200",
  gestioneducativa: "0210",
  formacionestudio: "1110",
  tiposexo: "1120",
  turno: "1130",
  tipocentroestudio: "0220",
  situacionpostulante: "1010",
  lugarbiometrico: "1159"
};

export const SELECT_ESTADOS = [
  { value: "", label: "TODOS" },
  { value: "1", label: "INCOMPLETO" },
  { value: "2", label: "PENDIENTE" },
  { value: "3", label: "OBSERVADO" },
  { value: "4", label: "ACEPTADO" },
  { value: "5", label: "LLENADO_VPI" },
  { value: "6", label: "INSCRITO" },
  { value: "7", label: "ACEPTADO_BIOMETRICO" }
];

export const SELECT_ESTADOS_INGRESANTE = [
  { value: "", label: "TODOS" },
  { value: "0", label: "ANULADO" },
  { value: "1", label: "INCOMPLETO" },
  { value: "2", label: "PENDIENTE" },
  { value: "3", label: "OBSERVADO" },
  { value: "4", label: "FIRMADO" }
];

export const INSTITUCION = {
  DIAD: 1,
  CEPU: 2,
  ESPG: 3,
  VIAC: 4,
  SESP: 5
};

export const CONST_ACCESS_TOKEN = "token";

// Para agregar nuevo rol, solo ingresar aquí
export const ROLES_AVAILABLE = [
  { rol: "segundaespecialidad", nombre: "Segunda especialidad" },
  { rol: "organizador", nombre: "Organizador" },
  { rol: "administrador", nombre: "Administrador" },
  { rol: "directivo", nombre: "Nivel Directivo" },
  { rol: "registrador", nombre: "Registrador" },
  { rol: "especialista", nombre: "Especialista de Sistemas" }
];

export const OBJ_ROLES = {
  SEGUNDA_ESPECIALIDAD: "segundaespecialidad",
  ORGANIZADOR: "organizador",
  ADMINISTRADOR: "administrador",
  DIRECTIVO: "directivo",
  SUPERADMIN: "superadmin",
  REGISTRADOR: "registrador",
  ESPECIALISTA_SISTEMAS: "especialista"
};

// ----- Estructura del formulario -----

export const formLayout = {
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  },
  tailFormItemLayout: {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      }
    }
  }
};

export const optionsWithDisabled = [
  {
    label: "Activos",
    value: true
  },
  {
    label: "Desactivos",
    value: false
  }
];

export const ALTERNATIVES_SI_NO = [
  { id: 0, label: "NO" },
  { id: 1, label: "SI" }
];

export {
  PROCESO,
  NOMBRE_SISTEMA,
  TITLE_SISTEMA_SHORT,
  LABEL_ESTADO,
  LABEL_ESTADO_INGRESANTE,
  ESTADO,
  COLOR_ESTADO,
  COLOR_ESTADO_INGRESANTE,
  COLOR_ESTADO_TAILWIND,
  TITLE_SISTEMA_ABREV
};
