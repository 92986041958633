import { Tag, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import * as SHARED from "../../utils/shared";

/**
 * createColumns
 * Complemento para mostrar las tablas tanto como en antd y modo responsive
 * @param {Function} showEditModal
 * @param {Function} showDeleteModal
 * @param {Number} page
 */
export function createColumns(
  page = 1,
  pageSize = 10,
  handleOpenInNewTab = null,
  handleGoDetail = null,
  carrerasFilter = [],
  modalidadFilter = []
) {
  return [
    {
      title: "N.º",
      key: "index",
      render: (value, item, index) => (page - 1) * pageSize + index + 1
    },
    {
      title: "Asignado",
      key: "asignado",
      dataIndex: "asignado"
    },
    {
      title: "Estado",
      dataIndex: "idestado",
      key: "idestado",
      filters: [
        { text: "Anulado", value: 0 },
        { text: "Incompleto", value: 1 },
        { text: "Pendiente", value: 2 },
        { text: "Observado", value: 3 },
        { text: "Firmado", value: 4 }
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.idestado === value,
      render: (state) => {
        return (
          <Tag color={SHARED.COLOR_ESTADO_INGRESANTE[state]} key={state}>
            {SHARED.LABEL_ESTADO_INGRESANTE[state] || "Si informacion"}
          </Tag>
        );
      }
    },
    {
      title: "Nro.Doc.",
      dataIndex: "documentoregistro",
      key: "documentoregistro",
      sorter: (a, b) => a.documentoregistro.localeCompare(b.documentoregistro),
      render: (_codigo, record) => (
        <Button type="link" onClick={() => handleGoDetail(record)}>
          {record.documentoregistro}
        </Button>
      ),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Apellido",
      dataIndex: "apellido",
      key: "apellido",
      sorter: (a, b) => a.apellido.localeCompare(b.apellido),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Carrera",
      dataIndex: "programaestudio",
      key: "programaestudio",
      filters: carrerasFilter.map((carrera) => ({ text: carrera, value: carrera })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.programaestudio) {
          return record.programaestudio.toString() === value.toString();
        }
        return false;
      },
      sorter: (a, b) => a.programaestudio.localeCompare(b.programaestudio),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Modalidad",
      dataIndex: "modalidadingreso",
      key: "modalidadingreso",
      filters: modalidadFilter.map((modalidad) => ({ text: modalidad, value: modalidad })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.modalidadingreso) {
          return record.modalidadingreso.toString() === value.toString();
        }
        return false;
      },
      sorter: (a, b) => a.modalidadingreso.localeCompare(b.modalidadingreso),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Documentos",
      dataIndex: "constanciaentregada",
      key: "constanciaentregada",
      render: (constanciaentregada) => {
        if (!constanciaentregada) return <p></p>;
        return <p className="font-bold">SÍ</p>;
      },
      filters: [
        { text: "SÍ", value: 1 },
        { text: "NO", value: 0 }
      ],
      onFilter: (value, record) => record.constanciaentregada === value,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Acciones",
      key: "action",
      render: (record) => (
        <Button size="small" icon={<EyeOutlined />} onClick={() => handleOpenInNewTab(record)} />
      )
    }
  ];
}
